import { RefundIcon } from 'components/SvgIcons/RefundIcon';
import { ShareInMapIcon } from 'components/SvgIcons/ShareInMapIcon';
import { StoreCreditIcon } from 'components/SvgIcons/StoreCreditIcon';
import { TrashIcon } from 'components/SvgIcons/TrashIcon';
import { Country } from 'country-state-city';
import CartIcon from 'public/image/cart-icon-filled.svg';

const GENDERS = (t: any) => [
  { label: t('male'), value: 1, key: 1 },
  { label: t('female'), value: 2, key: 2 },
];

const MARITAL_STATUS = [
  { label: 'Unmarried', value: '0', key: 1 },
  { label: 'Married', value: '1', key: 2 },
];

const SessionStoragekeys = ['addressInput', 'lakum_points', 'apply_store_credit', 'appliedCoupon', 'couponSuccess'];
const SessionStoragekeysAfterOrder = [
  'addressInput',
  'lakum_points',
  'apply_store_credit',
  'appliedCoupon',
  'couponSuccess',
  'shipping_method',
];

const returnPageReasonsList = [
  { value: 'Accidental order', key: 'accidental_order' },
  { value: 'Better price available', key: 'better_price_available' },
  { value: 'Damaged/missing delivery', key: 'damaged_delivery' },
  { value: 'Different from what was ordered', key: 'different_from_what' },
  { value: 'Expired Product', key: 'expired_product' },
  { value: 'Other', key: 'other' },
];

const statusColorCode = {
  IN_PROGRESS: { bg: '#D9730E40', color: '#D9730E' },
  APPROVED: { bg: '#35B05040', color: '#35B050' },
  ORDERED: { bg: '#347FA940', color: '#347FA9' },
  NEW: { bg: '#21212840', color: '#000005' },
  REJECTED: { bg: '#D34C4840', color: '#D34C48' },
  RETURNED: { bg: '#0396E840', color: '#0396E8' },
};

const prescriptionColorsMap = {
  prescription_new: statusColorCode.NEW,
  prescription_ready_to_purchase: statusColorCode.APPROVED,
  prescription_inprogress: statusColorCode.IN_PROGRESS,
  prescription_cancelled: statusColorCode.REJECTED,
  prescription_Ordered: statusColorCode.ORDERED,
};

const ordersCardsColorsMap = {
  returned: statusColorCode.RETURNED,
  completed: statusColorCode.APPROVED,
  in_progress: statusColorCode.IN_PROGRESS,
  canceled: statusColorCode.REJECTED,
};

const LocalStoragekeys = ['cart'];
const SessionKeysToKeep = ['shipping_method'];
const LocalKeysToKeep = ['shipping_address', 'paymentMethod'];
const COUNTRIES = Country.getAllCountries().map((item) => ({
  label: item.name,
  value: item.name,
  key: item.isoCode,
  flag: item.flag,
}));

export enum LanguageType {
  Ar = 'ar-sa',
  ArWord = 'عربي',
  En = 'en-sa',
}

export enum LanguageTypeShort {
  ArShortCap = 'AR',
  EnShortCap = 'EN',
  ArShortSmall = 'ar',
  EnShortSmall = 'en',
}

export const Active = [
  'pending',
  'pending_cod',
  'waiting_payment',
  'waiting_auth',
  'waiting_capture',
  'holded',
  'processing',
  'driver_assigned',
  'picked',
  'packed',
  'ready_for_store_pickup',
  'ready_to_dispatch',
  'driver_arrived',
  'dispatched',
  'in_patients_area',
  'near_home',
  'near_door',
  'canceled',
  'closed',
  'waiting_stock_movement',
  'complete',
  'returned',
  'shipped',
  'picked',
  'packed',
  'dispatched',
  'invoiced',
];

export const Canceled = [
  'failed_attempt',
  'cancellation_requested',
  'rejected',
  'canceled_cc',
  'canceled_apple_pay',
  'canceled_cod',
  'canceled_mada',
  'return_pickup_failed',
];

export const Returned = [
  'waiting_refill_date',
  'return_requested',
  'return_dispute',
  'return_rejected',
  'return_accepted',
  'return_accepted_dropoff',
  'return_complete',
  'waiting_refund',
];

export const Complete = [
  'refund_failed',
  'refund_complete',
  'rescheduled',
  'delivery_failed',
  'return_request_failed',
  'canceled',
  'refunded',
  'delivered',
];

export const START_MINUTES = '01';

export const START_SECOND = '00';

export const START_DURATION = 10;

const AddressPageNumber = 7;

const AlgoliaTimeout = 60;

const filterCOmponentLeftSideList = {
  category: { labelEn: 'Category', labelAr: 'فئات', en: 'category.category_id', ar: 'category.category_id' },
  brand: { labelEn: 'Brand', labelAr: 'ماركة', en: 'attributes.brand.en', ar: 'attributes.brand.ar' },
  composition: {
    labelEn: 'Composition',
    labelAr: 'التركيب',
    en: 'attributes.composition.en',
    ar: 'attributes.composition.ar',
  },
  primaryUnit: {
    labelEn: 'Primary Unit Of Measure',
    labelAr: 'وحدة القياس الأولية',
    en: 'attributes.primary_unit_of_measure.en',
    ar: 'attributes.primary_unit_of_measure.ar',
  },
  properties: {
    labelEn: 'properties',
    labelAr: 'الخصائص',
    en: 'attributes.properties.en',
    ar: 'attributes.properties.ar',
  },
  spf: { labelEn: 'SPF', labelAr: 'عامل الحماية من الشمس', en: 'attributes.spf.en', ar: 'attributes.spf.ar' },
  storage: {
    labelEn: 'Storage',
    labelAr: 'تخزين',
    en: 'attributes.storage.en',
    ar: 'attributes.storage.ar',
  },
  country: {
    labelEn: 'Manufacturer Country Name',
    labelAr: 'الاسم البلاد الشركة المصنعة',
    en: 'attributes.manufacturer_country_name.en',
    ar: 'attributes.manufacturer_country_name.ar',
  },
  indication: {
    labelEn: 'Indication',
    labelAr: 'الغرض من الإستعمال',
    en: 'attributes.indication.en',
    ar: 'attributes.indication.ar',
  },
  price: { labelEn: 'Price', labelAr: 'سعر', en: 'price_range.en', ar: 'price_range.ar' },
  weight: {
    labelEn: 'Weight',
    labelAr: 'وحدة الوزن',
    en: 'attributes.weight_uom_code',
    ar: 'attributes.weight_uom_code',
  },
  howToUse: {
    labelEn: 'How to use',
    labelAr: 'كيفية الأستخدام',
    en: 'attributes.how_to_use.en',
    ar: 'attributes.how_to_use.ar',
  },
  none: { labelEn: '', labelAr: '', en: 'n.a', ar: 'n.a' },
};

const CARDS = [
  {
    id: 1,
    name: 'VISA - 0001',
    card_number: 4005550000000001,
    expiry_date: '05/25',
    card_security_code: 123,
    card_holder_name: 'Test Name',
    matched: true,
  },
  {
    id: 2,
    name: 'AMEX - 4564',
    card_number: 345678901234564,
    expiry_date: '05/25',
    card_security_code: 1234,
    card_holder_name: 'Test Name',
    matched: true,
  },
  {
    id: 3,
    name: 'MasterCard - 8902',
    card_number: 4557012345678902,
    expiry_date: '05/25',
    card_security_code: 123,
    card_holder_name: 'Test Name',
    matched: true,
  },
];

export enum AlgoliaEventNames {
  clickedObjectIDsAfterSearch = 'Product Clicked',
  addedToCartObjectIDsAfterSearch = 'Product Added To Cart',
  convertedFilters = 'Filters Converted',
  viewedObjectIDs = 'Product Viewed',
  clickedFilters = 'Filters Clicked',
  purchasedObjectIDsAfterSearch = 'Product Purchased',
}

export enum AlgoliaEventTypes {
  clickedObjectIDsAfterSearch = 'clickedObjectIDsAfterSearch',
  clickedObjects = 'clickedObjectIDs',
  addedToCartObjectIDsAfterSearch = 'addedToCartObjectIDsAfterSearch',
  addedToCartObjectIDs = 'addedToCartObjectIDs',
  convertedFilters = 'convertedFilters',
  viewedObjectIDs = 'viewedObjectIDs',
  clickedFilters = 'clickedFilters',
  purchasedObjectIDsAfterSearch = 'purchasedObjectIDsAfterSearch',
  viewedFilters = 'viewedFilters',
}

export const MADA_CARDS = [
  440647, 440795, 446404, 457865, 968208, 457997, 474491, 636120, 417633, 468540, 468541, 468542, 468543, 968201,
  446393, 409201, 458456, 484783, 462220, 455708, 410621, 455036, 486094, 486095, 486096, 504300, 440533, 489318,
  489319, 445564, 968211, 410685, 406996, 432328, 428671, 428672, 428673, 968206, 446672, 543357, 434107, 407197,
  407395, 412565, 431361, 604906, 521076, 529415, 535825, 543085, 524130, 554180, 549760, 968209, 524514, 529741,
  537767, 535989, 536023, 513213, 520058, 558563, 588982, 589005, 531095, 530906, 532013, 968204, 422817, 422818,
  422819, 428331, 483010, 483011, 483012, 589206, 968207, 419593, 439954, 530060, 531196, 420132, 421141, 588845,
  403024, 968205, 42689700, 406136,
];

const DEFAULT_MAP_CENTER = { lat: 24.6918102, lng: 46.7302736 };

const OTP_SEND_COUNTDOWN = 60000;

const eventConfig = {
  viewItemListEvent: {
    defaultName: 'view_item_list',
    adjustName: '530bto',
    listTypes: {
      wishlist: 'wishlist',
    },
  },
  view_cart: {
    defaultName: 'view_cart',
    adjustName: '530bto',
  },
  selectContentEvent: {
    defaultName: 'select_item',
    adjustName: 'umuuyd',
  },
  viewItemEvent: {
    defaultName: 'view_item',
    adjustName: 'o2d8wk',
  },
  addToCartEvent: {
    defaultName: 'add_to_cart',
    adjustName: 'ntu488',
  },
  removeFromCartEvent: {
    defaultName: 'remove_from_cart',
    adjustName: 'xeq14i',
  },
  beginCheckoutEvent: {
    defaultName: 'begin_checkout',
    adjustName: 'yr1ir1',
  },
  purchaseEvent: {
    defaultName: 'purchase',
    adjustName: 'lul527',
  },
  addPaymentInfoEvent: {
    defaultName: 'add_payment_info',
    adjustName: 'bw1chq',
  },
  addShippingInfoEvent: {
    defaultName: 'add_shipping_info',
    adjustName: '2ucfaq',
  },
};

export {
  AddressPageNumber,
  CARDS,
  COUNTRIES,
  DEFAULT_MAP_CENTER,
  GENDERS,
  returnPageReasonsList,
  LocalKeysToKeep,
  LocalStoragekeys,
  MARITAL_STATUS,
  OTP_SEND_COUNTDOWN,
  SessionKeysToKeep,
  SessionStoragekeys,
  statusColorCode,
  ordersCardsColorsMap,
  SessionStoragekeysAfterOrder,
  eventConfig,
  prescriptionColorsMap,
  filterCOmponentLeftSideList,
  AlgoliaTimeout,
};

export const subTransactionTypeTranslations = {
  Redeemed: { key: 'redeemed', icon: <CartIcon /> },
  Purchased: { key: 'purchased', icon: <StoreCreditIcon /> },
  Returned: { key: 'returned', icon: <ShareInMapIcon color='#595959' /> },
  Expired: { key: 'expired', icon: <TrashIcon /> },
  TransferredIn: { key: 'transferred_in', icon: <RefundIcon className='rotate-180' /> },
  'Transferred In': { key: 'transferred_in', icon: <RefundIcon className='rotate-180' /> },
  TransferredOut: { key: 'transferred_out', icon: <RefundIcon /> },
  'Transferred Out': { key: 'transferred_out', icon: <RefundIcon /> },
};

export const filterOptions = (t: any) => ({
  time: [
    {
      id: 1,
      name: t('all'),
      value: 'all',
    },
    {
      id: 2,
      name: t('this_month'),
      value: 'last_1',
    },
    {
      id: 3,
      name: t('last_months', { count: 3 }),
      value: 'last_3',
    },
    {
      id: 4,
      name: t('last_months', { count: 6 }),
      value: 'last_6',
    },
    {
      id: 5,
      name: t('last_months', { count: 12 }),
      value: 'last_12',
    },
  ],
  type: [
    {
      id: 6,
      name: t('all'),
      value: 'all',
    },
    {
      id: 7,
      name: t('consumed_points'),
      value: 'consumed_points',
    },
    {
      id: 8,
      name: t('gained_points'),
      value: 'gained_points',
    },
  ],
});
