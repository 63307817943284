// queries/useNotifications.js
import { useQuery } from '@tanstack/react-query';
import { notifications } from 'models/notifications'; // This can now be your Firebase query instead
import useCookieStorage from 'hooks/useCookieStorage';
import useStoreView from 'hooks/useStoreView';
import useFcmToken from './useFcmToken';

export default function useNotifications() {
  const [customerId] = useCookieStorage('isCustomerLoggedIn', false, {});
  const [storeView] = useStoreView();
  const { fcmToken, notificationPermissionStatus } = useFcmToken();

  const { data, refetch } = useQuery(
    ['notifications', storeView],
    async () => {
      // You can fetch notifications from Firebase using the notificationToken
      // Or listen to `notification` for real-time notifications received
      return notificationPermissionStatus ? notifications() : [];
    },
    {
      enabled: !!customerId && !!fcmToken,
      staleTime: 1000 * 60 * 5,
    },
  );

  return {
    data,
    refetch,
  };
}
