// components/Notification.js
import { useState } from 'react';
import { parseCookies } from 'nookies';
import { setCookiesWithNookies } from 'utils/set-cookies-with-nookies';
import useNotifications from 'queries/useNotifications';
import { NotificationIcon } from 'components/SvgIcons/NotificationIcon';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import useRtl from 'hooks/useRtl';

const NotificationCard = dynamic(() => import('./NotificationCard'), { ssr: false });

const Notification = () => {
  const { data: notifications } = useNotifications();
  const [showNotifications, setShowNotifications] = useState(false);
  const unreadNotificationsCount = getUnreadNotificationsCount(notifications?.length);
  const isRtl = useRtl();

  return (
    <div className='relative items-center flex w-[50px]'>
      <button
        aria-label='notification'
        className={unreadNotificationsCount > 0 ? 'bell-icon' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setShowNotifications((prev) => !prev);
          setCookiesWithNookies('notificationsHadReadCount', String(notifications?.length));
        }}
      >
        <NotificationIcon />
        {unreadNotificationsCount > 0 && (
          <span
            className={clsx(
              'absolute top-0 rtl:lg:right-[15px] rtl:md:right-[15px] rtl:right-[20px] px-[3px] right-[10px] text-green-800 bg-white w-auto h-auto border rounded-full border-green-900 text-[8px]',
              {
                'md:translate-x-[-10px]': isRtl,
              },
            )}
          >
            {unreadNotificationsCount}
          </span>
        )}
      </button>
      {showNotifications && (
        <NotificationCard setShowNotifications={setShowNotifications} notifications={notifications} />
      )}
    </div>
  );
};

const getUnreadNotificationsCount = (totalNotificationsCount) => {
  if (!totalNotificationsCount) {
    return 0;
  }
  return totalNotificationsCount - (Number(parseCookies().notificationsHadReadCount) || 0);
};

export default Notification;
