/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect } from 'react';
import { useLoading } from 'hooks/useLoading';
import { useTranslation } from 'next-i18next';
import useRtl from 'hooks/useRtl';
import CommonHeader from 'components/Header/CommonHeader';
import Header from 'components/Header/LogistrationHeader';
import dynamic from 'next/dynamic';
import SideBar from 'components/SideBar';
import { useRouter } from 'next/router';
import { MetaTag, OpenGraph, Twitter } from 'next-seo/lib/types';
import CustomModal from 'components/Modal/priceUpdateModal';
import { getMessaging, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../firebase-config';
import useNotifications from 'queries/useNotifications';
import firebaseApp from 'utils/firebase/firebase';

interface Page {
  children: React.ReactNode;
  showFooter?: boolean;
  isLogin?: boolean;
  className?: string;
  showSideBar?: boolean;
  subHeader?: boolean;
  sideBarTitle?: string;
  sideBarBreadcrumb?: string;
  t?: any;
  isRtl?: boolean;
  title?: string;
  description?: string;
  openGraph?: OpenGraph;
  metaTags?: MetaTag[];
  twitter?: Twitter;
  canonical?: string;
  isLoadingPage?: boolean;
  isBot?: boolean;
  sideBarClassName?: string;
  sideBarBackClassName?: string;
  backColor?: string;
}

const styles = {
  background: '#F7F7F8',
};

const Layout = ({
  children,
  showFooter = true,
  isLogin = false,
  className = 'pb-0',
  showSideBar = false,
  subHeader = true,
  sideBarTitle = '',
  sideBarBreadcrumb = '',
  title,
  description,
  openGraph,
  metaTags,
  canonical,
  twitter,
  isLoadingPage = false,
  isBot = false,
  sideBarClassName = 'bg-[#fff] px-2',
  sideBarBackClassName = 'w-full mb-2 grid border-b grid-cols-[10%_80%] items-center px-2 h-[40px] bg-[#fff] text-gray-900',
  backColor = '#595959',
}: Page) => {
  const [loading, setLoading] = useLoading();
  const isRtl = useRtl();
  const { t } = useTranslation('common');
  const router = useRouter();

  const CommonFooter = dynamic(() => import('components/Footer/CommonFooter'), { ssr: false });
  const Loader = dynamic(() => import('components/Loader'), { ssr: false });
  const CookiePolicyPopup = dynamic(() => import('components/CookiePolicyPopup'), { ssr: false });

  const { refetch } = useNotifications();

  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    const messaging = getMessaging(firebaseApp);
    onMessage(messaging, (payload) => {
      console.log('Foreground push notification received:', payload);
      refetch();
      // Handle the received push notification while the app is in the foreground
      // You can display a notification or update the UI based on the payload
    });

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'REFETCH_NOTIFICATIONS') {
        console.log('Background push notification received, refetching notifications...');
        refetch();
      }
    });
  }

  useEffect(() => {
    if (!isBot) {
      const onStart = () => {
        setLoading(true);
      };
      const onComplete = () => {
        setLoading(false);
      };

      const onError = () => {
        setLoading(false);
      };

      router.events.on('routeChangeStart', onStart);
      router.events.on('routeChangeComplete', onComplete);
      router.events.on('routeChangeError', onError);

      return () => {
        router.events.off('routeChangeStart', onStart);
        router.events.off('routeChangeComplete', onComplete);
        router.events.off('routeChangeError', onError);
      };
    }
  }, []);

  return (
    <div
      dir={isRtl ? 'rtl' : ' ltr'}
      style={router.pathname !== '/links' ? styles : { backgroundColor: 'white' }}
      className={`${className} flex flex-col min-h-screen`}
    >
      {!isBot && <CookiePolicyPopup />}
      <CustomModal />
      {isLogin &&
      router.pathname !== '/login' &&
      router.pathname !== '/login-with-number' &&
      router.pathname !== '/signup' ? (
        <Header t={t} />
      ) : (
        !isLoadingPage && (
          <CommonHeader
            title={title}
            description={description}
            metaTags={metaTags}
            openGraph={openGraph}
            t={t}
            subHeader={subHeader}
            canonical={canonical}
            twitter={twitter}
          />
        )
      )}
      {isLogin ? (
        children
      ) : showSideBar ? (
        <SideBar
          t={t}
          sideBarBackClassName={sideBarBackClassName}
          sideBarClassName={sideBarClassName}
          title={sideBarTitle}
          breadcrumb={sideBarBreadcrumb}
          backColor={backColor}
        >
          {children}
        </SideBar>
      ) : (
        children
      )}
      {router.pathname.includes('login') && <div className='mt-[4%]' />}
      {showFooter && !isBot && !isLoadingPage && <CommonFooter t={t} />}
      {loading && <Loader />}
    </div>
  );
};

export default Layout;
